import React, { useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Typography, Modal, IconButton, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseIconIMG from '../../images/Icon.svg';
import classes from './SessionTimeoutModal.module.css';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import { getAccessToken } from '../../services/tokenService';
import {
  SESSION_MODAL_CONTENT,
  SESSION_MODAL_TITLE,
  SESSION_RESTART,
  SESSION_MODAL_KEY,
  SESSION_TIMEOUT,
  PROGRESA_CONTIGO,
} from '../../constants';
import { clearContext } from '../clearContext';
import { clearDoChecklistContext } from '../CommercialDeposit/DragDropFiles/DocLabelTypes';
import { productNameURLMapping } from '../../services/productNameURLMapping';

function SessionTimeoutModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { setOpenSessionTimeoutModal, startTimer, pingTimer, history, openModal } = props;
  const leadGenerationContext = useContext(LeadGenerationContext);

  const handleClose = async () => {
    setOpenSessionTimeoutModal(false);
    clearContext(leadGenerationContext);
    clearDoChecklistContext(leadGenerationContext);

    const token = await getAccessToken();
    let tokenOtp;
    // if (leadGenerationContext.flowTypeETB === 'EtbFlow') {
    //   tokenOtp = await getAccessTokenForOtp();
    // }
    if (token || tokenOtp) {
      startTimer();

      if (tokenOtp && leadGenerationContext.flowTypeETB === 'EtbFlow') {
        leadGenerationContext.setOtpToken(tokenOtp);
        leadGenerationContext.setAccessToken(token);
        pingTimer(tokenOtp);
      } else if (token) {
        leadGenerationContext.setAccessToken(token);
        pingTimer(token);
      }

      if (leadGenerationContext.flowInvitation === 'invitation-form') {
        leadGenerationContext.setCurrentPage('invi-flow-info');
        history.push({
          pathname: `/${leadGenerationContext.language?.toLowerCase()}/commercial-form/business-account-info`,
        });
      } else if (leadGenerationContext.flowTypeUpload === 'upload-later') {
        leadGenerationContext.setCurrentPage('upload-documents-later');
        history.push({
          pathname: `/${leadGenerationContext.language?.toLowerCase()}/commercial-form/upload-documents-later`,
        });
      } else if (leadGenerationContext.flowTypeQRcode === 'QRCodeGeneration') {
        leadGenerationContext.setCurrentPage('qr-code');
        history.push({
          pathname: `/${leadGenerationContext.language?.toLowerCase()}/qr-code`,
        });
      } else if (leadGenerationContext.flowTypePlaidVerifyScreen === 'PlaidVerificationScreen') {
        leadGenerationContext.setCurrentPage('plaid-verification-screen');
        history.push(
          `/?ao_location=${leadGenerationContext.location}&ao_language=${leadGenerationContext.language}&ao_flow=${leadGenerationContext.flowTypePlaidVerifyScreen}`
        );
      } else if (leadGenerationContext.currentPage === 'verification-screen') {
        history.push(
          `/?&ao_language=${leadGenerationContext.language}&ao_flow=${leadGenerationContext.flowTypeVerifyScreen}`
        );
      } else if (leadGenerationContext.productName === PROGRESA_CONTIGO) {
        const regex = /&/g;
        const newOrganization = leadGenerationContext.organization.replace(regex, '%26');
        history.push(
          `/?ao_productType=${leadGenerationContext.productType}&ao_productName=${leadGenerationContext.productName}&ao_location=${leadGenerationContext.location}&ao_language=${leadGenerationContext.language}&utm_campaign=${leadGenerationContext.utmCampaign}&utm_source=${leadGenerationContext.utmSource}&utm_medium=${leadGenerationContext.utmMedium}&utm_content=${leadGenerationContext.utmContent}&utm_term=${leadGenerationContext.utmTerm}&salesAdvisor=${leadGenerationContext.salesAdvisor}&Organization=${newOrganization}`
        );
      } else if (leadGenerationContext.flowTypeETB === 'EtbFlow') {
        leadGenerationContext.setCurrentPage('etb-login');
        history.push({
          pathname: `/${leadGenerationContext.location?.toLowerCase()}/${leadGenerationContext.language?.toLowerCase()}/express/retail-form/${productNameURLMapping(
            leadGenerationContext.productName
          )}/etb-login`,
        });
      } else {
        history.push(
          `/?ao_productType=${leadGenerationContext.productType}&ao_productName=${leadGenerationContext.productName}&ao_location=${leadGenerationContext.location}&ao_language=${leadGenerationContext.language}&utm_campaign=${leadGenerationContext.utmCampaign}&utm_source=${leadGenerationContext.utmSource}&utm_medium=${leadGenerationContext.utmMedium}&utm_content=${leadGenerationContext.utmContent}&utm_term=${leadGenerationContext.utmTerm}`
        );
      }
    }
  };
  useEffect(() => {
    openModal ? setOpen(true) : setOpen(false);
  }, [openModal]);

  const textLineOne = SESSION_MODAL_TITLE;
  const textLineTwo = SESSION_MODAL_CONTENT;

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      role="dialog"
      open={open}
      aria-describedby="dialog1Desc dialog2Desc"
    >
      <div className={classes.sessionmodal}>
        <Grid container className={classes.sessioncontainer}>
          <IconButton
            src={CloseIconIMG}
            name="closeicon"
            onClick={handleClose}
            tabIndex="0"
            data-testid="close"
            id="businessAddressEdit"
            disableRipple
            className={classes.closeOverlay}
          >
            <img
              alt={t(`close`, { defaultValue: 'close' })}
              tabIndex="0"
              src={CloseIconIMG}
              width="20px"
              height="20px"
            />
          </IconButton>
        </Grid>
        <Grid container>
          <Card className={classes.rootSessionTimeoutModal}>
            <CardContent className={classes.card_content}>
              <Typography className={classes.textOneSessionStyle} id="dialog1Desc">
                {t(SESSION_TIMEOUT, { defaultValue: textLineOne })}
              </Typography>
              <Typography className={classes.textTwoSessionStyle} id="dialog2Desc">
                {t(SESSION_MODAL_KEY, { defaultValue: textLineTwo })}
              </Typography>
              <Button
                disableFocusRipple
                autoFocus
                className={classes.restart_app_button}
                onClick={handleClose}
              >
                {t(SESSION_RESTART, { defaultValue: SESSION_RESTART })}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </Modal>
  );
}

export default withRouter(SessionTimeoutModal);

SessionTimeoutModal.propTypes = {
  setOpenSessionTimeoutModal: PropTypes.func,
  startTimer: PropTypes.func,
  pingTimer: PropTypes.func,
  openModal: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
