import { saveKnockOffInformation } from '../../services/commercialService';

export const commonFunction = (value) => {
  if (value) {
    return value;
  }
  return null;
};

export const saveKnockOffData = async (
  leadGenerationContext,
  knockOffReason,
  vouchedJobId,
  usersToString
) => {
  const data = {};
  let response = null;
  data.applicationId = leadGenerationContext.applicationId;
  data.applicantId = leadGenerationContext.applicantId;
  data.language = leadGenerationContext.language;
  data.location = leadGenerationContext.location;
  data.utmCampaign = leadGenerationContext.utmCampaign;
  data.utmMedium = leadGenerationContext.utmMedium;
  data.utmContent = leadGenerationContext.utmContent;
  data.utmTerm = leadGenerationContext.utmTerm;
  data.utmSource = leadGenerationContext.utmSource;
  data.knockOffReason = knockOffReason;
  data.vouchedJobId = vouchedJobId;
  data.usersToString = usersToString;
  // add arg allData data.allreason = allData;
  response = await saveKnockOffInformation(data, leadGenerationContext.accessToken);
  return response;
};
