/* eslint-disable max-lines */
import axios from 'axios';
import { commonUtility } from '../components/LeadGeneration/UtilityFunctions';
import { businessMapping } from './businessMapping';
import {
  formatSSNToNumbers,
  createSourceInformation,
  createDocumentProofsArray,
  returnIndustryListInEN,
} from './formServiceUtils';

export const addSignerInfo = (item) => ({
  ApplicantCategory: 'Secondary',
  ApplicantID: item.uuid,
  // OwnershipPercentage: "20",
  CustName: {
    FrstName: item.firstName,
    MdleName: null,
    LstName: item.lastName,
    // "SecondLstName": "Three"
  },
  Contacts: [
    {
      ContactType: 'email',
      ContactValue: item.email,
      Validation: {
        ValidatedBy: item.emailResponse?.validationData
          ? item.emailResponse?.validationData?.validatedBy
          : null,
        Status: item.emailResponse?.validationData
          ? item.emailResponse?.validationData?.status
          : null,
        ResultCodes: item.emailResponse?.validationData
          ? item.emailResponse?.validationData?.resultCode
          : null,
        Message: item.emailResponse?.validationData
          ? item.emailResponse?.validationData?.message
          : null,
        Timestamp: item.emailResponse?.validationData
          ? item.emailResponse?.validationData?.timestamp
          : null,
      },
    },
    {
      ContactType: 'mobile',
      ContactValue: `(${item.areaCode}) ${item.mobile}`,
      Validation: {
        ValidatedBy: item?.phoneResponse?.validationResponse
          ? item.phoneResponse.validationResponse.validatedBy
          : null,
        Status: item?.phoneResponse?.validationResponse
          ? item.phoneResponse.validationResponse.status
          : null,
        ResultCodes: item?.phoneResponse?.validationResponse
          ? item.phoneResponse.validationResponse.resultCodes
          : null,
        Message: item?.phoneResponse?.validationResponse
          ? item.phoneResponse.validationResponse.message
          : null,
        Timestamp: item?.phoneResponse?.validationResponse
          ? item.phoneResponse.validationResponse.timestamp
          : null,
      },
    },
  ],
  ApplicantType: item.participantType,
  Status: 'INITIATED',
});
export const addSignerInfoArray = (addSignerData) => {
  const addSignerObj = [];

  if (addSignerData.length > 0) {
    // eslint-disable-next-line array-callback-return
    addSignerData.map((item) => {
      const dataObj = addSignerInfo(item);
      addSignerObj.push(dataObj);
    });
  }

  return addSignerObj;
};
export const saveBusinessAdditionalInfo = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureBusinessAdditionalInfo',
    Application: {
      ApplicationID: data.applicationId ? data.applicationId : null,
      TemplateVersion: '2021-11-24',
      Source: createSourceInformation(data),
      Business: {
        AdditionalInfo: {
          TaxId: data.businessTaxId ? data.businessTaxId : null,
          Website: data.website ? data.website : null,
          NetWorth: data.netWorth ? data.netWorth : null,
          AnnualSale: data.annualSales ? data.annualSales : null,
          NumberOfEmployee: data.noOfEmployees ? data.noOfEmployees : null,
          IndustryType: data.industryType ? returnIndustryListInEN(data.industryType) : null,
          TimeInBusiness: data.timeInBusiness,
        },
        BusinessCreditDetails: {
          CreditAmount: data.creditAmount,
          CreditRequestReason: data.creditRequestReason,
          CollateralForCredit: data.collateralCredit,
          Collateral: data.specifyCollateral,
        },
        StateDeptInfo: {
          StateDepartmentId: commonUtility(data.stateDeptRegistry),
        },
      },
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveBusinesSuriInfo = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureSuriInfo',
    Application: {
      ApplicationID: data.applicationId ? data.applicationId : null,
      TemplateVersion: '2021-11-24',
      Source: createSourceInformation(data),
      Business: {
        SuriInfo: {
          locationDetails: data.locationDetails,
          merchantDetails: data.merchantDetails,
          certificateDetails: data.certificateDetails,
          Verified: data.verified ? 'true' : 'false',
          SuriId: data.suriId,
          BusinessAddress: data.businessAddress,
          BusinessOwnerAddress: data.businessOwnerAddress,
          BusinessName: data.businessName,
          BusinessOwnerName: data.businessOwnerName,
          BusinessAddressChanged: data.businessAddressChanged
            ? data.businessAddressChanged.replace(/\n/g, ' ')
            : '',
          BusinessOwnerAddressChanged: data.businessOwnerAddressChanged
            ? data.businessOwnerAddressChanged.replace(/\n/g, ' ')
            : '',
          CertificateDetails: data.CertificateDetails,
          // isBusinessAddressChanged: data.isBusinessAddressChanged,
          // isBusinessOwnerAddressChanged: data.isBusinessOwnerAddressChanged,
          ...(data.docMandatory && { IsAddressDocMandatory: true }),
          ...((data.businessAddDocId || data.businessOwnAddDocId) && data.docMandatory
            ? {
                Documents: [
                  ...(data.businessAddDocId
                    ? [
                        {
                          DocType: 'BusinessAddressChanged',
                          DocId: data.businessAddDocId,
                        },
                      ]
                    : []),
                  ...(data.businessOwnAddDocId
                    ? [
                        {
                          DocType: 'BusinessOwnerAddressChanged',
                          DocId: data.businessOwnAddDocId,
                        },
                      ]
                    : []),
                ],
              }
            : {}),
        },
      },
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveBusinessContactInformation = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureBusinessContact',
    Application: {
      ApplicationID: commonUtility(data.applicationId),
      TemplateVersion: '2021-11-24',
      Source: createSourceInformation(data),
      Products: [
        {
          ProductType: commonUtility(data.productType),
          ProductName: commonUtility(businessMapping(data.productName)),
          Category: 'Primary',
        },

        ...(data.productName === 'Elite Account'
          ? [
              {
                ProductType:
                  data.productName === 'Elite Account'
                    ? 'Savings Account'
                    : commonUtility(data.productType),
                ProductName: commonUtility(businessMapping(data.productName)),
                Category: 'Secondary',
              },
            ]
          : []),
      ],
      Applicants: [
        {
          ApplicantCategory: 'DigitalApplicant',
          ApplicantID: commonUtility(data.applicantId),
          CustName: {
            FrstName: data.firstName,
            LstName: data.lastName,
          },
          Contacts: [
            {
              ContactType: 'email',
              ContactValue: data.email,
              Validation: {
                ValidatedBy: data.emailVerificationResponse
                  ? data.emailVerificationResponse.validatedBy
                  : null,
                Status: data.emailVerificationResponse
                  ? data.emailVerificationResponse.status
                  : null,
                ResultCodes: data.emailVerificationResponse
                  ? data.emailVerificationResponse.resultCode
                  : null,
                Message: data.emailVerificationResponse
                  ? data.emailVerificationResponse.message
                  : null,
                Timestamp: data.emailVerificationResponse
                  ? data.emailVerificationResponse.timestamp
                  : null,
              },
            },
            {
              ContactType: 'mobile',
              ContactValue:
                data.location !== 'VI' ? `(${data.areaCode}) ${data.phone}` : data.phone,
              Validation: {
                ValidatedBy: data.phoneValidationResponse
                  ? data.phoneValidationResponse.validatedBy
                  : null,
                Status: data.phoneValidationResponse ? data.phoneValidationResponse.status : null,
                ResultCodes: data.phoneValidationResponse
                  ? data.phoneValidationResponse.resultCodes
                  : null,
                Message: data.phoneValidationResponse ? data.phoneValidationResponse.message : null,
                Timestamp: data.phoneValidationResponse
                  ? data.phoneValidationResponse.timestamp
                  : null,
              },
            },
          ],
          RelationShipWithCompany: commonUtility(data.relationWithCompany),
          ...(data.productType === 'Credit' && {
            CorporateTitle: commonUtility(data.corporateTitle),
          }),
        },
      ],
      Business: {
        LegalName: data.businessLegalName,
        Type: data.businessType,
      },
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveBusinesComplianceInfo = async (
  selectedValue,
  applicationId,
  applicantId,
  accessToken
  // productType
) => {
  const formData = {
    Event: 'CaptureBusinessCompliance',
    Application: {
      ApplicationID: applicationId || null,
      TemplateVersion: '2021-11-24',
      // Source: createSourceInformation(data),
      Business: {
        ComplianceQuestions: [
          {
            Name: 'IsInvolvedInNotAllowedBusiness',
            Description: selectedValue || null,
            Answer: {
              Type: 'boolean',
              Value: false,
            },
          },
          {
            Name: 'IsSuriIdActive',
            Description: 'Do you have an Active SURI Merchant Number?',
            Answer: {
              Type: 'boolean',
              Value: true,
            },
          },
          // ...(productType === 'Credit' &&
          {
            Name: 'IsBusinessOnBankruptcy',
            Description: 'Is your business currently on bankruptcy?',
            Answer: {
              Type: 'boolean',
              Value: false,
            },
            // }),
          },
        ],
      },
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
const getVouchedData = (data) => {
  if (data.vouchedIdData && Object.keys(data.vouchedIdData).length > 0) {
    return data.vouchedIdData;
  }
  return null;
};
export const commonTernary = (condition, result1, result2) => {
  if (condition) {
    return result1;
  }
  return result2;
};
export const saveBusinessAccountInfo = async (data, accessToken, participantData) => {
  const vouchedData = getVouchedData(data);

  const arr1 = [
    {
      ApplicantCategory: 'DigitalApplicant',
      ApplicantID: commonUtility(data.applicantId),
      OwnershipPercentage: data.ownerPercentage,
      CustTaxId: formatSSNToNumbers(data.ssn),
      Status: 'INITIATED',
      // check vouchedjobid
      ...(data.vouchedJobId
        ? {
            CustName: {
              FrstName: commonUtility(data.firstName),
              MdleName: commonUtility(data.middleName),
              LstName: commonUtility(data.lastName),
              SecondLstName: commonUtility(data.secondLastName),
            },
          }
        : {}),
      Signer: commonTernary(data.signer === true, 'Yes', 'No'),
      Owner: commonTernary(data.ownership === true, 'Yes', 'No'),
      // check vouchedjobid
      ...(data.vouchedJobId
        ? {
            Identifications: [
              {
                IdenDOB: data.dob ? data.dob : null,
                IdenType: vouchedData && commonUtility(vouchedData.type),
                IdNumber: vouchedData && commonUtility(vouchedData.id),
                StartDate: vouchedData && commonUtility(vouchedData.issueDate),
                ExpiryDate: vouchedData && commonUtility(vouchedData.expireDate),
                IssuingCountry: vouchedData && commonUtility(vouchedData.country),
                IdenGender:
                  vouchedData && vouchedData.gender && commonUtility(vouchedData.gender.gender),
              },
            ],
          }
        : {
            Identifications: [
              {
                IdenDOB: data.dob ? data.dob : null,
                IdenType: vouchedData && commonUtility(vouchedData.type),
                IdNumber: vouchedData && commonUtility(vouchedData.id),
                StartDate: vouchedData && commonUtility(vouchedData.issueDate),
                ExpiryDate: vouchedData && commonUtility(vouchedData.expireDate),
                IssuingCountry: vouchedData && commonUtility(vouchedData.country),
                IdenGender:
                  vouchedData && vouchedData.gender && commonUtility(vouchedData.gender.gender),
              },
            ],
          }),
      // check vouchedjobid and home addrss fields

      Addresses: [
        {
          AddrType: 'home',
          LineOne: commonUtility(data.address1),
          LineTwo: commonUtility(data.address2),
          AddrCity: commonUtility(data.city),
          AddrState: commonUtility(data.state),
          AddrCountry: commonUtility(data.country),
          ZipCode: commonUtility(data.zipCode),
          ...(data.isIdAddress === false && {
            BillType: data.utilityBill,
          }),

          Validation: {
            ValidatedBy: data.validatedBy,
            Status: commonTernary(data.validatedBy === 'Vouched', data.vouchedStatus, null),
            ResultCodes: null,
            Message: null,
            Timestamp: commonTernary(data.validatedBy === 'Vouched', data.vouchedTimestamp, null),
          },
          OCRVerifiedFlag: commonTernary(data.ocrAddressVerified, 'true', 'false'),
          OCRAddressEditFlag: commonTernary(data.ocrAddressEdit, 'true', 'false'),
          OcrExceptions: data.ocrErrors,
          ...(data.isIdAddress === false && {
            Documents: createDocumentProofsArray(data.DocId, 'Home Address Proof'),
          }),
        },
      ],

      // check vouchedjobid
      ...(data.vouchedJobId
        ? {
            AdditionalInfo: {
              Vouched: {
                JobId: commonTernary(data.vouchedJobId, data.vouchedJobId, null),
                Token: commonTernary(data.vouchedToken, data.vouchedToken, null),
                CrossCheck: data.crossCheckResponse,
                DlvStatusResult: commonTernary(data.dlvStatus, data.dlvStatus, ''),
              },
            },
          }
        : {}),
    },
  ];
  const arr2 = [...addSignerInfoArray(participantData)];
  const formData = {
    Event: 'CaptureAuthorizedUsers',
    Application: {
      ApplicationID: commonTernary(data.applicationId, data.applicationId, null),

      TemplateVersion: '2021-11-24',
      Source: createSourceInformation(data),
      Applicants: [...arr1, ...arr2],
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
export const saveBusinessComp = async (data, accessToken) => {
  const formData = {
    Event: 'AdditionalComplianceQuestions',
    Application: {
      ApplicationID: data.applicationId ? data.applicationId : null,
      TemplateVersion: '2021-11-24',
      Source: createSourceInformation(data),
      Business: {
        ComplianceQuestions: [
          {
            Name: 'AreEngaged',
            Description: 'Is the company engaged in any of the following?',
            Answer: {
              Type: 'boolean',
              Value: false,
            },
          },
          {
            Name: 'IsBulkCashServices',
            Description: 'Bulk Cash Services',
            Answer: {
              Type: 'boolean',
              Value: data.isBulk,
            },
          },
          {
            Name: 'IsFastCashServices',
            Description: 'Fast Cash Services',
            Answer: {
              Type: 'boolean',
              Value: data.isFast,
            },
          },
          // {
          //   Name: 'IsInternetGambling',
          //   Description: 'Internet Gambling',
          //   Answer: {
          //     Type: 'boolean',
          //     Value: data.isGamb,
          //   },
          // },
          {
            Name: 'PrivateATM',
            Description: "Private ATM's",
            Answer: {
              Type: 'boolean',
              Value: data.isAtm,
            },
            ...(data.isAtm && {
              Documents: createDocumentProofsArray(data.atmDocID, 'Private ATM Document'),
            }),
          },
          {
            Name: 'Act20',
            Description: 'Act No. 20',
            Answer: {
              Type: 'boolean',
              Value: data.isAct,
            },
            ...(data.isAct && {
              Documents: createDocumentProofsArray(data.act20DocID, 'Act 20 Certificate'),
            }),
          },
        ],
      },
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const validateSuri = async (suriNumber, language, accessToken) => {
  let result = null;

  try {
    result = await axios.get(
      `${process.env.REACT_APP_SURI_API}?website=suri&regNum=${suriNumber}&lang=${language}`,
      {
        headers: {
          appid: 'sales',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (error) {
    return error.response;
  }
  return result;
};

export const saveKnockOffInformation = async (data, accessToken) => {
  const formData = {
    Event: 'KnockOff',
    Application: {
      ApplicationID: data.applicationId,
      TemplateVersion: '2022-20-05',
      Source: createSourceInformation(data),
      Applicants: [
        {
          ApplicantID: data.applicantId ? data.applicantId : null,
          KnockOff: {
            KnockOffFlag: 'true',
            KnockOffReason: data.knockOffReason ? data.knockOffReason : 'No errors available',
            ...(commonUtility(data.vouchedJobId)
              ? { VouchedJobId: data.vouchedJobId, Feature: 'Vouched' }
              : {}),
            ...(commonUtility(data.usersToString) ? { ExceptionCodes: data.usersToString } : {}),
            // ...(commonUtility(data.allreason) ? { ExceptionCodes: data.allreason } : {}),
          },
        },
      ],

      Processing: { CaseReference: [] },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const createSourceInformationInvi = (data) => ({
  Language: data.language,
  Location: 'PR',
  UtmSource: data.utmSource,
  UtmMedium: data.utmMedium,
  UtmCampaign: data.utmCampaign,
  UtmContent: data.utmContent,
  UtmTerm: data.utmTerm,
  BranchCode: data.branchCode,
});

export const saveBusinessAccountInfoInvi = async (data, accessToken) => {
  const vouchedData = getVouchedData(data);
  const formData = {
    Event: 'CaptureAuthorizedUsers',
    Application: {
      ApplicationID: commonUtility(data.applicationId),
      ApplicantID: commonUtility(data.applicantId),
      TemplateVersion: '2021-11-24',
      Products: [
        {
          ProductType: 'CommercialInvitation',
          ProductName: 'SecondaryApplicant',
          Category: 'Primary',
        },
        ...(data.productName === 'Elite Account'
          ? [
              {
                ProductType:
                  data.productName === 'Elite Account' ? 'Savings Account' : 'CommercialInvitation',
                ProductName: 'SecondaryApplicant',
                Category: 'Secondary',
              },
            ]
          : []),
      ],
      Source: createSourceInformationInvi(data),
      Applicants: [
        {
          // ...(data.flowType && { IsAddressDocMandatory: 'jjj' }),
          Status: 'COMPLETED',
          ApplicantID: commonUtility(data.applicantId),
          ApplicantCategory: 'DigitalApplicant',
          OwnershipPercentage: data.ownerPercentage,
          Signer: commonTernary(data.signer === true, 'Yes', 'No'),
          Owner: commonTernary(data.ownership === true, 'Yes', 'No'),
          CustTaxId: formatSSNToNumbers(data.ssn),
          Contacts: [
            {
              ContactType: 'email',
              ContactValue: data.email,
              Validation: {
                ValidatedBy: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.validatedBy,
                  null
                ),
                Status: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.status,
                  null
                ),
                ResultCodes: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.resultCode,
                  null
                ),
                Message: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.message,
                  null
                ),
                Timestamp: commonTernary(
                  data.emailVerificationResponse,
                  data.emailVerificationResponse?.timestamp,
                  null
                ),
              },
            },
            {
              ContactType: 'mobile',
              ContactValue: `(${data.areaCode}) ${data.phone}`,
              Validation: {
                ValidatedBy: commonTernary(
                  data.phoneValidationResponse,
                  data.phoneValidationResponse?.validatedBy,
                  null
                ),
                Status: commonTernary(
                  data.phoneValidationResponse,
                  data.phoneValidationResponse?.status,
                  null
                ),
                ResultCodes: commonTernary(
                  data.phoneValidationResponse,
                  data.phoneValidationResponse?.resultCodes,
                  null
                ),
                Message: commonTernary(
                  data.phoneValidationResponse,
                  data.phoneValidationResponse?.message,
                  null
                ),
                Timestamp: commonTernary(
                  data.phoneValidationResponse,
                  data.phoneValidationResponse?.timestamp,
                  null
                ),
              },
            },
          ],
          CustName: {
            FrstName: commonUtility(data.firstName),
            MdleName: commonUtility(data.middleName),
            LstName: commonUtility(data.lastName),
            SecondLstName: commonUtility(data.secondLastName),
          },

          Identifications: [
            {
              IdenDOB: data.dob,
              IdenType: vouchedData && commonUtility(vouchedData.type),
              IdNumber: vouchedData && commonUtility(vouchedData.id),
              StartDate: vouchedData && commonUtility(vouchedData.issueDate),
              ExpiryDate: vouchedData && commonUtility(vouchedData.expireDate),
              IssuingCountry: vouchedData && commonUtility(vouchedData.country),
              IdenGender:
                vouchedData && vouchedData.gender && commonUtility(vouchedData.gender.gender),
            },
          ],
          Addresses: [
            {
              AddrType: 'home',
              LineOne: commonUtility(data.address1),
              LineTwo: commonUtility(data.address2),
              AddrCity: commonUtility(data.city),
              AddrState: commonUtility(data.state),
              AddrCountry: commonUtility(data.country),
              ZipCode: commonUtility(data.zipCode),
              Validation: {
                ValidatedBy: data.validatedBy,
                Status: commonTernary(data.validatedBy === 'Vouched', data.vouchedStatus, null),
                ResultCodes: null,
                Message: null,
                Timestamp: commonTernary(
                  data.validatedBy === 'Vouched' ? data.vouchedTimestamp : null
                ),
              },
              Documents: createDocumentProofsArray(data.DocId, 'Home Address Proof'),
            },
          ],
          AdditionalInfo: {
            Vouched: {
              JobId: commonTernary(data.vouchedJobId, data.vouchedJobId, null),
              Token: commonTernary(data.vouchedToken, data.vouchedToken, null),
              CrossCheck: data.crossCheckResponse,
              DlvStatusResult: commonTernary(data.dlvStatus, data.dlvStatus, ''),
            },
          },
          CorporateTitle: commonUtility(data.corporateTitle),
        },
      ],
      Processing: {
        CaseReference: [],
      },
    },
  };
  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};

export const invitationFlowInitialCall = async (appId, accessToken, applicanID) => {
  let response = null;
  try {
    response = await axios.post(
      process.env.REACT_APP_LINK_INITIAL_CALL,
      {
        applicationId: appId,
        invitation: 'true',
        applicantId: applicanID,
      },
      {
        headers: {
          appid: 'sales',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  } catch (e) {
    return response;
  }
  return response;
};
export const saveCommercialConsent = async (appId, token) => {
  let result = null;
  const body = {
    Event: 'CaptureBankerInfo',
    Application: {
      ApplicationID: appId,
    },
  };
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, body, {
      headers: {
        appid: 'sales',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return null;
  }
  return result;
};
